import React, { useCallback, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { PLACEHOLDER } from "helpers/constant";
import { Link, useHistory } from "react-router-dom";
import { hunts as HuntApplicants } from "../api/hunts";
import { hunts as ShortListApplicant } from "../api/hunts";
import RoundImage from "../component/Image/RoundImage";
import Button from "../component/Button/Button";
import SendNotification from "../helpers/notification";

const ApplicantsList = React.memo((props) => {
    // const applicants = props.applicants;
    const auth_user = useSelector((store) => store.user.user);
    const { huntId } = useParams();
    const dispatch = useDispatch();
    const [huntApplicants, setHuntApplicants] = useState([]);

    const getHuntApplicants = useCallback(() => {
        dispatch(
            HuntApplicants({
                param_path: huntId + "/applicants/interested",
            }),
        )
            .then((response) => {
                if (response.success) {
                    setHuntApplicants(response.data);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    });

    // shortlist candidate
    const shortList = (id) => {
        dispatch(
            ShortListApplicant({
                param_path: huntId + "/applicants/" + id + "/change_status/shortlisted",
                method: "POST",
            }),
        )
            .then((response) => {
                if (response.success) {
                    setHuntApplicants((p) => p.filter((c) => c.user.id !== id));
                    props.onShortList(response.data.applicant.user);
                    SendNotification(response.data.notification);
                    // setHasShortListed(true);
                }
            })
            .catch((err) => {
                console.log(err);
            });
        // setHuntApplicants(p => p.filter(c => c.user.id !== id));
    };
    useEffect(() => {
        getHuntApplicants();
    }, []);

    const renderList = () => {
        return huntApplicants.map((ha, index) => {
            return (
                <li key={index}>
                    {auth_user?.id != ha.user.id && (
                        <div className="row m-0">
                            <div className="comment-box col-md-12 py-2">
                                <div className="row d-flex align-items-center p-1 m-0">
                                    <div className="text-center mr-2">
                                        {ha.cv_id ? (
                                            <Link to={`/hunts/${huntId}/${ha.application_type == "special" ? "cv" : "resume"}/${ha.cv_id}`} target="_blank">
                                                <RoundImage className="img-thumbnail" src={ha.user.profile_pic || PLACEHOLDER} alt={ha.user.name} size="65px" />
                                            </Link>
                                        ) : (
                                            <Link to={`/hunts/${huntId}/${ha.application_type == "special" ? "cv" : "resume"}/${ha.applicant_id}`} target="_blank">
                                                <RoundImage className="img-thumbnail" src={ha.user.profile_pic || PLACEHOLDER} alt={ha.user.name} size="65px" />
                                            </Link>
                                        )}
                                    </div>
                                    <div className="flex-fill">
                                        <div className="comment-titles">
                                            <h6>
                                                {ha.cv_id ? (
                                                    <Link to={`/hunts/${huntId}/${ha.application_type == "special" ? "cv" : "resume"}/${ha.cv_id}`} target="_blank">
                                                        {ha.user.name}
                                                    </Link>
                                                ) : (
                                                    <Link to={`/hunts/${huntId}/${ha.application_type == "special" ? "cv" : "resume"}/${ha.applicant_id}`} target="_blank">
                                                        {ha.user.name}
                                                    </Link>
                                                )}
                                            </h6>
                                            <small className="text-muted d-block">@{ha.user.username}</small>
                                        </div>
                                        <p className="mb-0">{ha.user.intro}</p>
                                    </div>
                                    <div className="">
                                        <Button type="button" className="form__button btn-sm" onClick={() => shortList(ha.user.id)}>
                                            <i className="icofont-plus mr-2"></i>ShortList
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </li>
            );
        });
    };

    return (
        <>
            {auth_user?.id == props.huntDetail?.created_by?.id && huntApplicants?.length > 0 && (
                <div className="comment-area product mt-3">
                    <div className="d-flex align-items-center justify-content-between my-4">

                        <h4 className="comment-title w-50 mb-0"> Interested Candidates
                        </h4>
                        <span className="cursor-pointer" onClick={() => console.log("call see all page")}>
                            See All
                        </span>
                    </div>
                    <ul className="comments">{renderList()}</ul>
                </div>
            )}
        </>
    );
});

export default ApplicantsList;
