import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import ThemeLayout from "../../../layout/themeLayout";
import Button from "../../../component/Button/Button";
import { useParams } from "react-router-dom";
import ApplicantsList from "../../../Widgets/ApplicantsList";
import HuntsWidget from "../../../Widgets/Hunts";
import { hunts as huntsApi } from "../../../api/hunts";
import SuggestedUsersWidget from "../../../Widgets/SuggestedUsers";
import SendNotification from "../../../helpers/notification";
import BackButton from "../../../component/BackButton/BackButton";
import ConfirmModal from "../../../component/ConfirmModal/ConfirmModal";
import { toast } from "react-toastify";
import { useRef } from "react";
import SquareImage from "../../../component/Image/SquareImage";
import RoundImage from "../../../component/Image/RoundImage";
import { PLACEHOLDER } from "../../../helpers/constant";
import ApplyJobModal from "../../../component/ApplyJob/ApplyJobModal";

const HuntDetail = React.memo((props) => {
    const auth_user = useSelector((store) => store.user.user);
    const history = useHistory();
    const { huntId } = useParams();
    const dispatch = useDispatch();
    const [huntDetail, setHuntDetail] = useState([]);
    const [hunts, setHunts] = useState([]);
    const [shortlistedApplicants, setShortlistedApplicants] = useState(null);
    const [hasApplied, setHasApplied] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);

    const btnClose = useRef();
    const btnDelete = useRef();

    //hunt detail get func
    const getHuntDetail = useCallback(() => {
        dispatch(
            huntsApi({
                isBackground: false,
                param_path: huntId,
            }),
        )
            .then((response) => {
                if (response.success) {
                    setHuntDetail(response.data);
                    setHasApplied(response.data.has_applied);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    });

    // apply job
    const applyJob = useCallback(() => {
        if (hasApplied) {
            setShowConfirm(true);
            return;
        }
        dispatch(
            huntsApi({
                param_path: huntId + "/applicants",
                method: "POST",
            }),
        )
            .then((response) => {
                if (response.success) {
                    setHasApplied(true);
                    toast.success(response.message);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    });

    const unApply = useCallback(() => {
        dispatch(
            huntsApi({
                param_path: huntDetail.id + "/unapply",
                method: "POST",
            }),
        )
            .then((response) => {
                if (response.success) {
                    toast.success(response.message);

                    setHasApplied(false);
                    setShowConfirm(false);
                }
            })
            .catch((e) => {
                console.log(e);
            });
    });

    const closeJob = () => {
        btnClose.current?.showLoader(true);

        let status = huntDetail.status === "open" ? "close" : "open";

        dispatch(
            huntsApi({
                param_path: huntDetail.id + `/close`,
                method: "POST",
                data: { status },
            }),
        )
            .then((response) => {
                if (response.success) {
                    btnClose.current?.showLoader(false);
                    toast.success(response.message);
                    history.push("/hunting");
                }
            })
            .catch((e) => {
                btnClose.current?.showLoader(false);
                console.log(e);
            });
    };

    const getHuntShortlistedApplicants = useCallback(() => {
        dispatch(
            huntsApi({
                param_path: huntId + "/applicants/shortlisted",
            }),
        )
            .then((response) => {
                if (response.success) {
                    let applicants = response.data.map((d) => {
                        let user = d.user;
                        user.application_type = d.application_type;
                        user.cv_id = d.cv_id;
                        return user;
                    });
                    setShortlistedApplicants(applicants);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    });

    //hunts get func
    const getHunts = useCallback(() => {
        dispatch(huntsApi({}))
            .then((response) => {
                if (response.success) {
                    setHunts(response.data.filter((hunt) => hunt.id != huntId));
                }
            })
            .catch((err) => {
                console.log(err);
            });
    });

    useEffect(() => {
        getHuntShortlistedApplicants();
    }, [dispatch]);

    useEffect(() => {
        getHunts();
    }, [dispatch]);

    useEffect(() => {
        getHuntDetail();
    }, [dispatch, huntId]);

    const handleShortList = (applicant) => {
        getHuntShortlistedApplicants();
    };

    const deleteJob = () => {
        btnDelete.current?.showLoader(true);
        dispatch(
            huntsApi({
                param_path: huntId,
                method: "DELETE",
            }),
        )
            .then((response) => {
                if (response.success) {
                    btnDelete.current?.showLoader(false);
                    toast.success(response.message);
                    history.push("/hunting");
                }
            })
            .catch((e) => {
                btnDelete.current?.showLoader(false);
                console.log(e);
            });
    };

    return (
        <div>
            <ConfirmModal
                showModal={showConfirm}
                title={"Confirm"}
                message={`Are you sure you want to withdraw your application from '${huntDetail?.job_title}'?`}
                onClose={() => setShowConfirm(false)}
                onConfirm={unApply}
            />

            <ConfirmModal
                showModal={showConfirmDelete}
                title={"Confirm"}
                message={`Are you sure you want to delete '${huntDetail?.job_title}'?`}
                onClose={() => setShowConfirmDelete(false)}
                onConfirm={deleteJob}
            />

            {/* <ConfirmModal
                showModal={showConfirmDelete}
                title={"Confirm"}
                message={`Are you sure you want to delete '${huntDetail?.job_title}'?`}
                onClose={() => setShowConfirmDelete(false)}
                onConfirm={deleteJob}
            /> */}

            <ThemeLayout>
                <section>
                    <div className="gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div id="page-contents" className="row merged20">
                                        <div className="col-lg-9">
                                            <div className="main-wraper">
                                                {huntDetail.id && (
                                                    <div className="row">
                                                        <div className="col-lg-12 col-md-12 col-sm-12">
                                                            <div className="prod-detail">
                                                                <BackButton />
                                                                {auth_user.id && auth_user.id != huntDetail.created_by.id && (
                                                                    <div className="text-right">
                                                                        <ApplyJobModal job={huntDetail} />
                                                                    </div>
                                                                    // <div className="group-action-btn-sec float-right d-inline-block">
                                                                    //     <Button htmlType="submit" type="button" className={`form__button`} onClick={() => applyJob()}>
                                                                    //         {hasApplied ? "Withdraw" : "Apply"}
                                                                    //     </Button>
                                                                    // </div>
                                                                )}
                                                                {huntDetail.image && <SquareImage className="img-thumbnail my-3" src={huntDetail.image} width="100%" />}
                                                                <h4 className="mb-0 text-capitalize text-truncate" title={huntDetail.job_title}>
                                                                    {huntDetail.job_title}
                                                                </h4>
                                                                <span className="font-weight-bold">
                                                                    Salary:{" "}
                                                                    <i>
                                                                        ${huntDetail.min_salary} - ${huntDetail.max_salary}
                                                                    </i>
                                                                </span>
                                                                <div className="my-3">
                                                                    <Link to={`/profile/${huntDetail?.created_by?.id}`}>
                                                                        <h5>Posted By</h5>
                                                                        <RoundImage src={huntDetail?.created_by?.profile_pic || PLACEHOLDER} size="20px" />
                                                                        <span className="ml-2">{huntDetail?.created_by?.name}</span>
                                                                    </Link>
                                                                </div>
                                                                <p>{huntDetail.job_description}</p>
                                                                <ul className="item-info pl-0">
                                                                    <li>
                                                                        <span className="font-weight-bold">Business:</span> {huntDetail.business}
                                                                    </li>
                                                                    <li className="text-capitalize">
                                                                        <span className="font-weight-bold">Job Type: </span>
                                                                        {huntDetail.job_type?.type_name}
                                                                    </li>
                                                                    <li>
                                                                        <span className="font-weight-bold">Pay Type:</span> {huntDetail.pay_type?.pay_title}
                                                                    </li>
                                                                    <li>
                                                                        <span className="font-weight-bold">Location: </span>
                                                                        {huntDetail.location?.location_name}
                                                                    </li>
                                                                </ul>

                                                                {auth_user?.id == huntDetail?.created_by?.id && (
                                                                    <div className="group-action-btn-sec float-right d-inline-block">
                                                                        <Button type="button" className={`form__button mr-2`} onClick={() => history.push(`/hunt/${huntDetail?.id}/edit`)}>
                                                                            Edit
                                                                        </Button>
                                                                        <Button type="button" ref={btnDelete} className={`form__button mr-2`} onClick={() => setShowConfirmDelete(true)}>
                                                                            Delete
                                                                        </Button>
                                                                        <Button type="button" ref={btnClose} className={`form__button`} onClick={() => closeJob()}>
                                                                            {huntDetail.status == "open" ? "Close" : "Open"}
                                                                        </Button>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                <hr />
                                                {auth_user?.id == huntDetail?.created_by?.id && <ApplicantsList huntDetail={huntDetail} onShortList={handleShortList} />}
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            {hunts.length > 0 && <HuntsWidget hunts={hunts} heading="Other Hunts" />}
                                            {shortlistedApplicants?.length > 0 && auth_user?.id == huntDetail?.created_by?.id && (
                                                <SuggestedUsersWidget
                                                    huntId={huntId}
                                                    hideBtns={true}
                                                    imgsize="30px"
                                                    users={shortlistedApplicants}
                                                    heading="Shortlisted Users"
                                                    seeAll={`/all/shortlisted/${huntDetail?.id}`}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </ThemeLayout>
        </div>
    );
});

export default HuntDetail;
